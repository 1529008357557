/* Desktop View */
.template-slider-container {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four templates per row for desktop */
    grid-gap: 20px;
    width: 80vw; /* Use 80% of the viewport width */
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .template-option {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  
  .template-buttons {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 10px;
  }
  
  .template-button {
    flex: 1;
    text-align: center;
    padding: 8px;
    margin: 0 5px;
    background-color: #ffffff;
    color: #f72a7b;
    border-radius: 5px;
    border: 1px solid #f72a7b;
    cursor: pointer;
    font-size: 14px;
  }
  
  /* Tablet View - Between 768px and 1024px */
  @media (max-width: 1024px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr); /* Three templates per row for tablet */
      grid-gap: 15px;
      width: 90vw;
    }
  
    .image {
      height: 250px; /* Adjust image height */
    }
  
    .template-button {
      font-size: 13px;
    }
  }
  
  /* Mobile View - Media Query */
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr); /* Two templates per row for mobile */
      grid-gap: 10px;
      width: 95vw; /* Use 95% of the viewport width */
    }
  
    .image {
      height: 200px; /* Adjust image height for mobile */
    }
  
    .template-buttons {
      width: 100%;
    }
  
    .template-button {
      font-size: 12px; /* Smaller font for mobile */
    }
  }
  
  /* Small Mobile View - Media Query for very small screens */
  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr); /* Single template per row for small screens */
      grid-gap: 10px;
      width: 95vw;
    }
  
    .image {
      height: 180px; /* Smaller image for very small devices */
    }
  
    .template-button {
      font-size: 11px; /* Smallest font size for tiny devices */
    }
  }
  