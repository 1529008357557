/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333;
}

.preview-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  color: #3399cc;
  margin-bottom: 20px;
}

/* Loading Overlay */
.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 1.2rem;
  color: #999;
  background: #f4f4f9;
}

/* PDF Image Preview */
.pdf-image-preview {
  margin: 20px auto;
  max-width: 100%; /* Limit width of the preview container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-image {
  max-width: 100%; /* Ensure the image scales down if necessary */
  max-height: 80vh; /* Limit height to 60% of the viewport height */
  object-fit: contain; /* Maintain aspect ratio without cropping */
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Buttons Container */
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.buttons-container button {
  padding: 12px 25px;
  font-size: 1rem;
  color: #fff;
  background: linear-gradient(90deg, #3399cc, #287ea4);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.buttons-container button:hover {
  background: linear-gradient(90deg, #287ea4, #1e5f7c);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.buttons-container button:active {
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

/* Template Selection */
.template-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.template-thumbnail {
  width: 100px;
  height: 140px;
  object-fit: cover;
  border: 2px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, border-color 0.3s ease;
}

.template-thumbnail:hover {
  transform: scale(1.05);
  border-color: #3399cc;
}

.template-thumbnail.selected {
  border-color: #3399cc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  .pdf-image {
    max-height: 70vh; /* Further limit height on smaller screens */
  }

  .buttons-container {
    gap: 15px;
  }

  .buttons-container button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .template-thumbnail {
    width: 80px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .buttons-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .buttons-container button {
    width: 90%; /* Stretch buttons for better alignment on small screens */
    padding: 10px 15px;
    font-size: 1rem;
  }

  .pdf-image {
    max-height: 60vh; /* Adjust height further for very small screens */
  }
  .preview-container {
    padding-top: 20%;

  }
}
.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #333;
}
