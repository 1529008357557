/* src/components/Footer.css */
.footer {
  background: #f72a7b;
  color: white;
  padding: 40px 0 20px;
  text-align: center;
  margin-top: 1%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1000px;
  margin: auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  padding: 0px;
}

.logo-text {
  font-size: 1.8rem;
  font-weight: bold;
}

.footer-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 1rem;
  line-height: 1.6;
}

.contact span {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  margin: 5px;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-links a {
  margin: 0 10px;
  color: #6200ff;
  font-size: 1.5rem;
  text-decoration: none;
}

.social-links a:hover {
  color: #ffffff;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.9rem;
}
