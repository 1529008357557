.join-whatsapp-group {
  margin: 50px;
}

.group-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 100px;
}

.group-table th, .group-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.group-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.group-info-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  width: 100%; /* Make button fill the cell */
  max-width: 150px; /* Ensure it's not too wide */
}

.group-info-button:hover {
  background-color: #45a049;
}

/* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure the popup is on top */
}

/* Popup box */
.popup-box {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%; /* Adjust width for mobile view */
  max-width: 400px;
  text-align: center;
  position: relative; /* For close button positioning */
}

.popup-box h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.popup-box p {
  font-size: 14px;
  margin-bottom: 10px;
}

/* Group link button */
.group-link-button {
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  width: 100%; /* Ensure button is responsive */
}

.group-link-button:hover {
  background-color: #1976D2;
}

/* Close button */
.close-button {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  background-color: #d32f2f;
}

/* Mobile specific styles */
@media (max-width: 768px) {
  .join-whatsapp-group {
    margin: 20px;
  }

  .group-info-button {
    font-size: 12px;
    padding: 8px 16px;
    max-width: 100%; /* Ensure it scales properly */
  }

  .popup-box {
    padding: 15px;
    max-width: 350px;
  }

  .group-link-button, .close-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}
