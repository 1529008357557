/* General styling for the container */
.business-container {
  max-width: 100%;
  margin: 0 auto;
  margin-top: -9rem;
  padding: 0px;
  font-family: 'Lato-Black', sans-serif;
  color: #333;
}
@font-face {
  font-family: 'Lato-Black';
  src: url('../../public/Lato-Black.ttf') format('truetype');
  font-style: normal;
}
/* About section styling */
.about-section {
  background: #f9f9f9;
  padding: 20px;
  width:80%;
  margin:0 auto;
  border-radius: 10px;
  margin-bottom: 40px;
  text-align: justify;
}

.about-section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #d74401;
}

.about-section p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

/* Video section styling */
.video-section {
  background: #e9ecef;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
  text-align: center;
}

.video-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #d74401;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Templates section styling */
.templates-section {
  margin-bottom: 3%;
  text-align: center;
  padding: 0;
}

.templates-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  background-color: #f6287b;
  color: white;
  margin-left: 20%;
  margin-right: 20%;

  border-radius: 50px;

}

/* New category button styling */
.filter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  gap: 5px;
}

.filter-buttons button {
  background: white;
  color: #520000;
  border: none;
  width: 100px;
  height: 50px;
  padding: 2px 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  white-space: nowrap; /* Prevent buttons from wrapping text */
}

.filter-buttons button.active,
.filter-buttons button:hover {
  background: linear-gradient(45deg, #82009c, #610505);
  color: white;
  font-weight: bolder;
  transform: translateY(-2px);
}

.template-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(3, 1fr));
  gap: 0px;
}

.template-option {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.template-option img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, z-index 0.3s ease;
}


.customize-button {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #d74401;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.template-option:hover .customize-button {
  display: block;
}

.customize-button:hover {
  background: #ff7300;
  transform: translateY(-2px);
}

/* Responsive styling */
@media (max-width: 768px) {
  .about-section,
  .video-section
   {
    padding: 15px;
  }

  .templates-section{
    padding: 0px;
    background-color: rgb(255, 255, 255);

    width: 100%;
  }

  .filter-buttons {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }

  .filter-buttons button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .templates-section h2,
  .about-section h2,
  .video-section h2 {
    font-size: 1.5rem;
  }
  .templates-section h2{
    font-size: 1rem;
    margin-bottom: 20px;
    background-color: #f6287b;
    color: white;
    margin-left: 25%;
    border-radius: 50px;
    padding: 15px;
  }

  .template-options {
    grid-template-columns: repeat(2, 1fr); /* Show 2 templates side by side */
  }
}
.contact-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  max-width: 300px;
  margin: 16px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 11rem;
}

.contact-name {
  font-size: 1.5em;
  margin-bottom: 8px;
  color: #d74401; /* Your specified color palette */
}

.contact-email,
.contact-phone {
  margin: 4px 0;
  font-size: 1em;
  color: #555;
}
