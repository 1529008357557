.input-form-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 6rem;
}
.required::after {
  content: " *";
  color: red;
}


.form-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
  display: flex;
  margin: 0 auto;
}

.image-upload {
  text-align: center;
}

.image-label {
  cursor: pointer;
  color: blue;
}

.center-text-input {
  margin-top: 10px;
  text-align: center;
  width: 80%;
}

.center-text-input input {
  width: 50%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.form-group {
  flex: 1 1 20%;
  position: relative;
}
.ssbutton{
  width: 250px;
  height: 50px;
}
label {
  display: block;
  margin-bottom: 5px;
  color: #000;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

input[type="text"],
input[type="date"],
input[type="time"],
input[type="dropdown"],

select {
  width: calc(100% - 10px);
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin-bottom: 10px;
}



select {
  width: calc(100% - 8px);
  padding: 10px;
  padding-left: 0px;
  font-size: 16px;
  padding-bottom: 5px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  background-color: #007bff; /* Added button color */
}

button:hover {
  background-color: #0056b3; /* Added button hover color */
}

.date-time-select,
.date-select,
.time-select {
 
  gap: 15px;
}

.date-time-select select,
.date-select select,
.time-select select {
  flex: 1 1;
  height: 38px;
  width: 110px;
  margin-bottom: 0px;
}
label select{
  padding: 0;
}
.add-button {
  display: inline-block;
  padding: 3px 8px;
  font-size: 12px;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  width: 100px;
}

.add-button:hover {
  background-color: #e68900;
}

.translation-hint {
  display: none;
  cursor: pointer;
  color: blue;
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
}

input:focus + .translation-hint,
select:focus + .translation-hint {
  display: block;
}

.multiple-input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 49%;
}

.multiple-input-container input {
  width: calc(100% - 10px);
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-group input {
  flex: 1;

}

.translation-hint-group {
  display: flex;
  flex-direction: column;
}

.translation-hint {
  cursor: pointer;
  color: blue;
  margin-top: -10px;
  margin-bottom: 10px;
}

.more-button {
  display: inline-block;
  padding: 3px 8px;
  font-size: 12px;
  background-color: #00ff1e;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  height: 40px;
  width: 90px;
}

.more-button:hover {
  background-color: #00cc16;
}

.date-select,
.time-select {
  width: 100%;
  height: fit-content;

}


.apple {
  display: flex;
  flex-direction: row;
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  z-index: 1;
  width: 100%;
}

.suggestions li {
  padding: 8px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #eee;
}

label select {
  border: 0px;
  margin-top: -7px;
}




/* Add this CSS to your existing CSS file */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffdddd; /* Light red background */
  border: 1px solid #f44336; /* Red border */
  color: #f44336; /* Red text color */
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 50%; /* Adjust width as needed */
  text-align: left; /* Left align text */
  display: ruby;
  align-items: center;
}

.popup h2 {
  margin-top: 0;
  font-size: 1.2em;
}

.popup p {
  margin: 0;
}

.popup-content {
  margin-left: 10px;
}

.popup-content h2 {
  font-size: 1em;
  margin-bottom: 5px;
}

.popup-content p {
  font-size: 0.9em;
}

.popup-icon {
  width: 30px;
  height: 30px;
  background-color: #f44336;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  margin-right: 10px;
}

.popup button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #d32f2f;
}

/* Add these styles to your existing CSS file */

body.no-scroll {
  overflow: hidden;
}

.blur-background {
  filter: blur(5px);
}

/* Existing CSS for the image popup */

.image-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the popup is on top */
}

.image-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 20px;
}

.image-popup img {
  max-width: 100px;
  margin: 10px;
  transition: transform 0.3s;
}

.image-popup img:hover {
  transform: scale(1.1);
}

.close-popup {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.close-popup:hover {
  background-color: darkred;
}

.section-heading { 
  display: grid;
  width: 100%;
  justify-content: center;

}

.elvish{
  margin-left: 15px;
}



/* Add this media query to your existing CSS file */
@media (max-width: 600px) {
  label select {
    border: white;
    box-shadow: white;
    text-decoration: none; 
    outline:none; 
    background-color: white;

  }
  .form-group {
    flex: 1 1 48%;
    position: relative;
  }

  .multiple-input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}


}


#centerText{
  width: 25%;
}

@media only screen and (max-width: 768px) {
  #centerText{
    width: 75%;
  }
}