.slideshow {
    position: relative;
    width: 100%;
    height: 40vh;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6287b;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {

    .slideshow{
    height: 21vh;
}
}

.slide {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.slide-image {
    max-width: 100%;
    max-height: 40%;
    object-fit: cover;
    border-radius: 50%;
}

.slide-title {
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
    padding: 10px;
}

.join-button {
    background-image: url('../../public/wp.png'); /* Use the correct relative path from the public folder */
    background-size: cover; /* Ensure the image covers the button area */
    background-position: center; /* Center the background image */
    color: white;
    font-size: 1.2rem;
    padding: 50px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    width: 130px;
    background-color: transparent;
    height: 200px;
    width: 200px;
}


.join-button:hover {
    background-color: transparent;
}

@media (max-width: 768px) {
    .slide-title {
        font-size: 1rem;
        padding: 5px;
    }

    .slide-image {
        max-height: 50%;
    }

    .join-button {
        font-size: 1rem;
        padding: 8px 16px;
    }
}
